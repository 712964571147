import React from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { sdsManagerUrlLocalization } from 'utils/i18n';

const Footer: React.FC = () => {
  const classes: any = useStyles();
  const { t } = useTranslation(['footer']);

  const location = window.location.pathname;
  const shouldDisplay = ![
    '/login',
    '/signup',
    '/reset-password',
    '/onesignin',
    'publicView',
    'pdfLabeling',
  ].some((item: string) => {
    return location.toLowerCase().indexOf(item) > -1;
  });

  if (!shouldDisplay) return null;

  return (
    <div className={classes.footer}>
      <a
        className={classes.linkText}
        rel="noreferrer"
        href={sdsManagerUrlLocalization() + '/privacy-policy'}
        target={'_blank'}
      >
        {t('footer:privacy_policy')}
      </a>
      <a
        className={classes.linkText}
        rel="noreferrer"
        href={sdsManagerUrlLocalization() + '/terms-and-conditions'}
        target={'_blank'}
      >
        {t('footer:terms')}
      </a>
    </div>
  );
};

export default Footer;
