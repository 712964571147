import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  collapsedItem: {
    paddingLeft: '30%',
  },
  downSplit: {
    borderBottom: '1px solid #E0E0E0',
    margin: '0 auto',
    marginRight: '10%',
    width: '60%',
  },
});

export default useStyles;
