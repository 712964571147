import { Icon } from '@mui/material';
import HomeIconSVG from 'icons/chemicals.svg';

interface ChemicalsIconProps {
    height: number;
    width: number;
}
const ChemicalsIcon = ({height, width}: ChemicalsIconProps) => (
  <Icon>
    <img alt="Icon" 
        src={HomeIconSVG} 
        height={height ? height : 25} 
        width={width ? width : 25} 
    />
  </Icon>
);

export default ChemicalsIcon;
