import axiosInstance from './axios-client';

const SDS_INVENTORY_URL = "https://inventory.sdsmanager.com";
const SDS_INVENTORY_RC_URL = "https://rc-inventory.sdsmanager.com";
const SDS_INVENTORY_RC_K_URL = "https://rc-inventory.k.sdsmanager";
const SDS_INVENTORY_STAGING_URL = "https://staging-inventory.sdsmanager.com";
const SDS_INVENTORY_STAGING_K_URL = "https://staging-inventory.k.sdsmanager";

/* The film “learn how to add sds files to your library” is outdated now, 
/  until it is updated we must hide it for all users. 
*/
export const SHOW_GUIDE_VIDEO_BOX = false;

export const getEnv = (): string => {
  const host = window.location.hostname;
  if (host.indexOf('localhost') > -1 || host.indexOf('127.0.0.1') > -1)
    return 'development';
  if (host.indexOf('staging-inventory.sdsmanager.com') > -1) return 'staging';
  if (host.indexOf('staging-inventory.k.sdsmanager') > -1) return 'staging_k';
  if (host.indexOf('rc-inventory.sdsmanager.com') > -1) return 'rc';
  if (host.indexOf('rc-inventory.k.sdsmanager') > -1) return 'rc_k';

  return 'production';
};

const workingEnv = getEnv()

export const BACKEND_URL =
  workingEnv === 'production'
  ? SDS_INVENTORY_URL
    : workingEnv === 'rc'
      ? SDS_INVENTORY_RC_URL
      : workingEnv === 'rc_k'
        ? 'https://rc-admin55.k.sdsmanager.com'
        : workingEnv === 'staging'
          ? SDS_INVENTORY_STAGING_URL
          : workingEnv === 'staging_k'
            ? 'https://staging-admin55.k.sdsmanager.com'
            : 'http://localhost:8000';

export const BASE_API_URL = `${BACKEND_URL}/api`;

export const EXCLUDE_REDIRECT_LOGIN_ERROR_CODES =
  [
    'IP_ADDRESS_FORBIDDEN',
  ]
export const TOO_MANY_SDS_ERROR_CODES =
  [
    'INVENTORY_LOCATION_BINDER_SDS_PACKAGE_LIMIT',
    'SUBSCRIPTION_PLAN_CANNOT_ADD_MORE_SDS',
    'SUBSCRIPTION_PLAN_CANNOT_ADD_MORE_SDS_REQUEST',
  ]

export const get = (path: string, params: object) => {
  return axiosInstance
    .get(path, { params: params })
    .then(function (response: any) {
      return response;
    })
    .catch(function (error: any) {
      return error.response;
    });
};

export const post = (path: string, data: object) => {
  return axiosInstance
    .post(path, data)
    .then(function (response: any) {
      return response;
    })
    .catch(function (error: any) {
      return error.response;
    });
};

export const deleteRequest = (path: string) => {
  return axiosInstance
    .delete(path)
    .then(function (response: any) {
      return response;
    })
    .catch(function (error: any) {
      return error.response;
    });
};

export const update = (path: string, data: object) => {
  return axiosInstance
    .patch(path, data)
    .then(function (response: any) {
      return response;
    })
    .catch(function (error: any) {
      return error.response;
    });
};
