import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    boxShadow: '0px 1px 2px rgb(0 0 0 / 20%)',
    position: 'fixed',
    zIndex: 10,
    width: '100%',
  },
  header: {
    display: 'flex',
    height: '65px',
    backgroundColor: '#FFFFFF',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerMobileLogo: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
  },
  headerDesktopLogo: {
    display: 'flex',
    alignItems: 'center',
  },
  headerSearchBar: {
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 'calc(50vw - 380px)',
    textAlign: 'center',
    [theme.breakpoints.down('xl')]: {
      left: 'calc(50vw - 290px)',
    },
    [theme.breakpoints.down('lg')]: {
      left: 'calc(50vw - 150px)',
    },
    [theme.breakpoints.down('md')]: {
      left: 'calc(50vw - 200px)',
    },
  },
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '20px',
  },
  arrow: {
    color: '#FFC93C',
  },
  tooltip: {
    fontSize: '1rem',
    backgroundColor: '#FFC93C',
    color: '#000000',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '10px',
    paddingBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  notificationWrapper: {
    display: 'flex',
    position: 'relative',
    marginRight: '10px',
    height: 20,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  countText: {
    position: 'absolute',
    color: '#fff',
    fontSize: '10px!important',
    right: 0,
    top: '-5px',
    borderRadius: '50%',
    backgroundColor: 'red',
    height: '15px',
    width: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emailCountText: {
    position: 'absolute',
    color: '#fff',
    fontSize: '10px!important',
    right: '-6px',
    top: '-5px',
    borderRadius: '50%',
    backgroundColor: 'red',
    height: '15px',
    width: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  appsIcon: {
    cursor: 'pointer',
    position: 'relative',
  },
  searchIcon: {
    marginLeft: 20,
    [theme.breakpoints.down('sm')]: {
      margin: '0 6px 0 0',
    },
  },
  popperContainer: {
    zIndex: 11,
    position: 'absolute',
    top: '40px',
    right: '-4px',
  },
  popper: {
    backgroundColor: '#FFC93C',
    color: '#000000',
    paddingLeft: '20px',
    paddingRight: '10px',
    paddingTop: '10px',
    paddingBottom: '10px',
    overflowX: 'unset',
    overflowY: 'unset',
    cursor: 'pointer',
    borderRadius: 4,
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      marginRight: '-0.71em',
      top: -10,
      right: 20,
      width: 10,
      height: 10,
      backgroundColor: '#FFC93C',
      transform: 'translate(-50%, 50%) rotate(-47deg)',
      clipPath:
        'polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))',
    },
  },
  poperContent: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    '& p': {
      padding: 0,
      margin: 0,
      fontSize: '14px',
      width: '140px',
    },
  },
  /* Search */
  iconLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  searchInput: {
    border: 0,
    background: 'none',
    outline: 'none',
    fontFamily: '"Montserrat",sans-serif',
  },
  searchWrapper: {
    borderRadius: '6px',
    padding: '4px 20px',
    backgroundColor: '#f7f7f7',
    color: '#52525F',
    display: 'flex',
  },
  searchIconContent: {
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
  },
  searchCategory: {
    fontSize: 14,
    fontWeight: 600,
    color: '#26272F',
    margin: '10px 0px',
  },
  searchSuggestion: {
    border: '1px solid #cccccc8f',
    borderRadius: '6px',
    width: 440,
    marginLeft: 40,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 20px',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'left',
      width: '70%',
      wordBreak: 'break-word',
    },
  },
  searchList: {
    display: 'flex',
    flexDirection: 'column',
  },
  sdsRequestIcon: {
    width: 18, 
    height: 17, 
    padding: 1, 
    fill: '#FFF!important', 
    backgroundColor: 'rgb(98 109 249 / 80%)', 
    borderRadius: '50%',
    marginRight: 9,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

export default useStyles;
