import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  footer: {
    margin: 0,
    height: 20,
    padding: '10px 20px',
    textAlign: 'right',
    backgroundColor: theme.palette.backgroundColor.main,
    color: '#000000',
    zIndex: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& a': {
      color: '#000000',
      marginLeft: 15,
      textDecoration: 'none',
      fontSize: 12,
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '5px 30px',
      '& a': {
        fontSize: 11,
      },
    },
    '& a:hover': {
      color: '#626DF9',
    }
  },
}));

export default useStyles;
