import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  changePassword as changePasswordApi,
  updateInventoryManagerSettings as updateInventoryManagerSettingsApi,
} from 'api';
import { getUser, impersonateUser } from 'api/auth/get';
import { getSubstancesCount, getNewerCount } from 'api/inventory/get';
import {
  ChangePasswordRequest,
  ChangePasswordResponse,
  ChangePasswordResponseDto,
  GetNewerCountResponse,
  GetNewerCountResponseDto,
} from 'interfaces/UserInterface';
import { ApiError, ErrorPayload } from 'interfaces/common/ApiResponseInterface';
import {
  UpdateInventoryManagerSettingsResponseDto,
  UpdateInventoryManagerSettingsParams,
  UpdateInventoryManagerSettingsResponse,
} from 'interfaces/location-management';

export const fetchUser = createAsyncThunk(
  'user/',
  async (_, { rejectWithValue }) => {
    const response = await getUser();
    if (response.status !== 200) return rejectWithValue(response.data);

    return response.data;
  }
);

export const fetchImpersonateUser = createAsyncThunk(
  'user/impersonate',
  async (params: { email: string }, { rejectWithValue }) => {
    const response = await impersonateUser(params.email);
    if (!response.data || response.data.status_code)
      return rejectWithValue(response.data);
    return response.data;
  }
);

export const getNewerSDSCount = createAsyncThunk<
  GetNewerCountResponseDto,
  undefined,
  {
    rejectValue: ErrorPayload;
  }
>('user/newerSDSCount', async (_, { rejectWithValue }) => {
  const response: GetNewerCountResponse | ApiError = await getNewerCount();
  if (response.status !== 200)
    return rejectWithValue(response.data as ErrorPayload);

  return response.data as GetNewerCountResponseDto;
});

export const getMoreSubstancesCount = createAsyncThunk(
  'user/getMoreSubstancesCount',
  async (_, { rejectWithValue }) => {
    const response = await getSubstancesCount();
    if (!response.data || response.data.status_code)
      return rejectWithValue(response.data);
    return response.data;
  }
);

export const updateInventoryManagerSettings = createAsyncThunk<
  UpdateInventoryManagerSettingsResponseDto,
  UpdateInventoryManagerSettingsParams,
  {
    rejectValue: ErrorPayload;
  }
>(
  'user/inventoryManagerSettings',
  async (params: UpdateInventoryManagerSettingsParams, { rejectWithValue }) => {
    const response: UpdateInventoryManagerSettingsResponse | ApiError =
      await updateInventoryManagerSettingsApi(params);

    if (response.status !== 200)
      return rejectWithValue(response.data as ErrorPayload);

    return response.data as UpdateInventoryManagerSettingsResponseDto;
  }
);

export const changePassword = createAsyncThunk<
  ChangePasswordResponseDto,
  ChangePasswordRequest,
  {
    rejectValue: ErrorPayload;
  }
>(
  'user/changePassword',
  async (params: ChangePasswordRequest, { rejectWithValue }) => {
    const response: ChangePasswordResponse | ApiError = await changePasswordApi(
      params
    );

    if (response.status !== 200)
      return rejectWithValue(response.data as ErrorPayload);

    return response.data as ChangePasswordResponseDto;
  }
);
