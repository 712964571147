import { createSlice } from '@reduxjs/toolkit';
import { FetchUserResponseDto } from 'interfaces/administration/UserInterface';
import UserInterface, {
  ChangePasswordResponseDto,
  GetNewerCountResponseDto,
  UserAdditionalColumnSetting,
} from 'interfaces/UserInterface';
import {
  revertImpersonateFromStorage,
  setImpersonateSessionToStorage,
} from 'utils/helper';
import {
  changePassword,
  fetchImpersonateUser,
  fetchUser,
  getMoreSubstancesCount,
  getNewerSDSCount,
  updateInventoryManagerSettings,
} from './thunks';
import { UpdateInventoryManagerSettingsResponseDto } from 'interfaces/location-management';
import { PrintLabelValueEnums } from 'enums/print-label-format-enums';
import { supportedLanguages } from 'utils/i18n';
import { PERMISSION, USER_PERMISSION } from 'enums/permissions.enums';

export interface UserState {
  loading: boolean;
  systemColumns: Array<UserAdditionalColumnSetting>;
  customColumns: Array<UserAdditionalColumnSetting>;
  newerCount: number;
  missingSDSCount: number;
  completedSDSRequestCount: number;
  archivedSDSCount: number;
  defaultLocation: string | number | null;
  sdsCount: number;
  totalSDSInLocationCount: number;
  hasArchivedSds: boolean;
  accessMyWorkProcedurePage: boolean;
  accessMyExposurePage: boolean;
  languageCode: string | null;
  printFormat: PrintLabelValueEnums | null;
  hasImportedBinder: boolean;
  customTagData: Array<UserAdditionalColumnSetting> | null;
  addSdsGuideCompleted: boolean;
  notFindSdsGuideCompleted: boolean;
  needToSetPwd: boolean;
  userData: UserInterface | null;
  userPermission: Array<USER_PERMISSION>;
  customerPermission: Array<PERMISSION>;
  sdsRequestEmailCount: number;
  deletedSDSCount: number;
  availableSds: number;
  /* Count of both the matched and unmatched substances. */
  allSubstancesCount: number;
  substanceInUseCount: number;
  substanceNotUseCount: number;
  /* End */
}

const initialState: UserState = {
  loading: false,
  systemColumns: [],
  customColumns: [],
  newerCount: 0,
  missingSDSCount: 0,
  completedSDSRequestCount: 0,
  archivedSDSCount: 0,
  defaultLocation: null,
  sdsCount: 0,
  totalSDSInLocationCount: 0,
  hasArchivedSds: false,
  accessMyWorkProcedurePage: false,
  accessMyExposurePage: false,
  languageCode: 'en',
  printFormat: null,
  hasImportedBinder: false,
  customTagData: null,
  userData: null,
  addSdsGuideCompleted: false,
  needToSetPwd: false,
  notFindSdsGuideCompleted: false,
  userPermission: [],
  customerPermission: [],
  sdsRequestEmailCount: 0,
  deletedSDSCount: 0,
  availableSds: 0,
  /* Count of both the matched and unmatched substances. */
  allSubstancesCount: 0,
  substanceInUseCount: 0,
  substanceNotUseCount: 0,
  /* End */
};

const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoading(state, action: { payload: boolean }) {
      state.loading = action.payload;
    },
    setDefaultLocation(state, action: { payload: string | number | null }) {
      state.defaultLocation = action.payload;
    },
    setSystemColumns(
      state,
      action: { payload: Array<UserAdditionalColumnSetting> }
    ) {
      state.systemColumns = action.payload;
    },
    setCustomColumns(
      state,
      action: { payload: Array<UserAdditionalColumnSetting> }
    ) {
      state.customColumns = action.payload;
    },
    setLanguageCode(state, action: { payload: string | null }) {
      state.languageCode = action.payload;
    },
    setPrintFormat(state, action: { payload: PrintLabelValueEnums | null }) {
      state.printFormat = action.payload;
    },
    setHasImportedBinder(state, action: { payload: boolean }) {
      state.hasImportedBinder = action.payload;
    },
    revertImpersonate(state) {
      state.loading = true;
      revertImpersonateFromStorage();
      window.location.reload();
      state.loading = false;
    },
    setCustomTagData(
      state,
      action: { payload: Array<UserAdditionalColumnSetting> }
    ) {
      state.customTagData = action.payload;
    },
    setAddSdsGuideCompleted(state, action: { payload: boolean | null }) {
      state.addSdsGuideCompleted = action.payload ?? false;
    },
    setNotFindSdsGuideCompleted(state, action: { payload: boolean | null }) {
      state.notFindSdsGuideCompleted = action.payload ?? false;
    },
    logout() {
      return initialState;
    },
    setUserPermission(state, action: { payload: Array<USER_PERMISSION> }) {
      state.userPermission = action.payload;
    },
    setCustomerPermission(state, action: { payload: Array<PERMISSION> }) {
      state.customerPermission = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder
      /* fetchUser */
      .addCase(
        fetchUser.fulfilled,
        (state: UserState, action: { payload: FetchUserResponseDto }) => {
          state.userData = action.payload;
          state.needToSetPwd = !!action.payload.need_to_set_password;
          state.printFormat =
            action.payload.inventory_manager_settings?.print_format;
          state.hasImportedBinder =
            action.payload.customer?.has_imported_binder;
          state.languageCode = supportedLanguages.includes(
            action.payload.language_code
          )
            ? action.payload.language_code
            : 'en';
          state.loading = false;
        }
      )
      .addCase(fetchUser.pending, (state: UserState) => {
        state.loading = true;
      })
      .addCase(fetchUser.rejected, (state: UserState) => {
        state.loading = false;
      })
      /* fetchImpersonateUser */
      .addCase(
        fetchImpersonateUser.fulfilled,
        (state: UserState, action: { payload: FetchUserResponseDto }) => {
          const isSuccess = setImpersonateSessionToStorage(
            action.payload.refresh_token,
            action.payload.access_token
          );
          if (isSuccess) window.location.reload();

          state.loading = false;
        }
      )
      .addCase(fetchImpersonateUser.pending, (state: UserState) => {
        state.loading = true;
      })
      .addCase(fetchImpersonateUser.rejected, (state: UserState) => {
        state.loading = false;
      })
      /* changePassword */
      .addCase(
        changePassword.fulfilled,
        (state: UserState, action: { payload: ChangePasswordResponseDto }) => {
          state.userData = action.payload;
          state.needToSetPwd = !!action.payload.need_to_set_password;
        }
      )
      /* getNewerSDSCount */
      .addCase(
        getNewerSDSCount.fulfilled,
        (state: UserState, action: { payload: GetNewerCountResponseDto }) => {
          state.newerCount = action.payload.count;
          state.accessMyWorkProcedurePage =
            action.payload.access_to_my_work_procedure_page;
          state.accessMyExposurePage =
            action.payload.access_to_my_exposure_page;
          state.sdsRequestEmailCount = action.payload.email_count;
        }
      )
      /* getMoreSubstancesCount */
      .addCase(
        getMoreSubstancesCount.fulfilled,
        (
          state: UserState,
          action: {
            payload: {
              missing_substances_count: number;
              completed_sds_request_count: number;
              archived_substances_count: number;
              accessed_sds_count: number;
              has_archived_sds: boolean;
              total_substances_in_location: number;
              deleted_substances_count: number;
              /* Count of both the matched and unmatched substances. */
              all_substances_count: number;
              substance_in_use_count: number;
              substance_not_use_count: number;
              available_sds: number;
            };
          }
        ) => {
          state.missingSDSCount = action.payload.missing_substances_count;
          state.completedSDSRequestCount =
            action.payload.completed_sds_request_count;
          state.archivedSDSCount = action.payload.archived_substances_count;
          state.sdsCount = action.payload.accessed_sds_count;
          state.hasArchivedSds = action.payload.has_archived_sds;
          state.totalSDSInLocationCount =
            action.payload.total_substances_in_location;
          state.deletedSDSCount = action.payload.deleted_substances_count;
          /* Count of both the matched and unmatched substances. */
          state.allSubstancesCount = action.payload.all_substances_count;
          state.substanceInUseCount = action.payload.substance_in_use_count;
          state.substanceNotUseCount = action.payload.substance_not_use_count;
          state.availableSds = action.payload.available_sds;
        }
      )
      /* updateInventoryManagerSettings */
      .addCase(
        updateInventoryManagerSettings.fulfilled,
        (
          state: UserState,
          action: {
            payload: UpdateInventoryManagerSettingsResponseDto;
          }
        ) => {
          state.customColumns = action.payload.custom_data_tag;
          state.systemColumns = action.payload.other_data_tag;
          state.printFormat = action.payload.print_format;
          state.addSdsGuideCompleted = action.payload.add_sds_guide_completed;
          state.notFindSdsGuideCompleted =
            action.payload.not_find_sds_guide_completed;
        }
      );
  },
});

export const {
  setLoading,
  revertImpersonate,
  setSystemColumns,
  setCustomColumns,
  setDefaultLocation,
  setLanguageCode,
  setPrintFormat,
  setHasImportedBinder,
  setCustomTagData,
  setAddSdsGuideCompleted,
  setNotFindSdsGuideCompleted,
  logout,
  setUserPermission,
  setCustomerPermission,
} = UserSlice.actions;
export default UserSlice.reducer;
