import { createAsyncThunk } from '@reduxjs/toolkit';
import { getInventoryStats } from 'api';
import { getTutorialVideosAndGuideArticles } from 'api/inventory/get';
import { ErrorPayload } from 'interfaces/common/ApiResponseInterface';
import {
  GetStatisticsResponseDto,
  TutorialVideosAndGuideArticles,
} from 'interfaces/home';

export const fetchStatistics = createAsyncThunk<
  GetStatisticsResponseDto,
  {},
  {
    rejectValue: ErrorPayload;
  }
>('home/fetchStatistics', async (_, { rejectWithValue }) => {
  const response = await getInventoryStats();
  if (response.status !== 200 || !response.data || response.data.status_code)
    return rejectWithValue(response.data as ErrorPayload);
  return response.data as GetStatisticsResponseDto;
});

export const fetchVideosAndArticles = createAsyncThunk<
  {
    videosAndArticles: TutorialVideosAndGuideArticles[];
    introVideo: { [key: string]: string };
  },
  undefined,
  {
    rejectValue: ErrorPayload;
  }
>('home/fetchVideosAndArticles', async (_, { rejectWithValue }) => {
  const response = await getTutorialVideosAndGuideArticles();
  if (response.status !== 200 || !response.data || response.data.status_code)
    return rejectWithValue(response.data as ErrorPayload);
  const data = response.data;
  let introVideo = {};
  const videosAndArticles = data.filter(
    (el: TutorialVideosAndGuideArticles) => {
      if (el.key === 'basic_walkthrough_video') {
        introVideo = el.video_urls ?? {};
        return false;
      }
      return true;
    }
  );
  return {
    videosAndArticles: videosAndArticles,
    introVideo: introVideo,
  };
});
