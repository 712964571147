import { Icon } from '@mui/material';

interface HomeIconProps {
    height: number;
    width: number;
    color: string;
}

const HomeIcon = ({ height, width, color }: HomeIconProps) => (
  <Icon style={{ height: height ? height : 25, width: width ? width : 25 }}>
    <svg
      width={width ? width : 25}
      height={height ? height : 25}
      viewBox="0 0 24 24"
      fill={color ? color : 'black'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 12.5H2L12 3.5L22 12.5H19V20.5H13V14.5H11V20.5H5V12.5ZM17 10.69L12 6.19L7 10.69V18.5H9V12.5H15V18.5H17V10.69Z"
        fillOpacity="0.6"
      />
    </svg>
  </Icon>
);

export default HomeIcon;
