import { Icon } from '@mui/material';

interface ChemicalsOnLocationProps {
    height: number;
    width: number;
    marginRight: string | number;
    color: string;
}

const ChemicalsOnLocation = ({ height, width, marginRight, color }: ChemicalsOnLocationProps) => (
  <Icon
    style={{
      height: height ? height : 22,
      width: width ? width : 22,
      marginRight: marginRight ? marginRight : '0',
    }}
  >
    <svg
      width={width ? width : 22}
      height={height ? height : 22}
      viewBox="0 0 18 14"
      fill={color ? color : 'black'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H8V14H0V0ZM18 0H10V2H18V0ZM18 4H10V6H18V4ZM10 8H18V10H10V8ZM18 12H10V14H18V12ZM6 2V12H2V2H6Z"
        fillOpacity="0.6"
      />
    </svg>
  </Icon>
);

export default ChemicalsOnLocation;
