import React, { useEffect } from 'react';
import {
  CircularProgress,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface MultiSelectFilterOption<K, V> {
  name: K;
  value: V;
  [propName: string]: any;
}

interface MultiSelectFilterProps {
  options: Array<MultiSelectFilterOption<any, any>>;
  label: string;
  defaultValue?: Array<MultiSelectFilterOption<any, any>>;
  width?: number | string;
  disabled?: boolean;
  loading?: boolean;
  onSelect?: (value: Array<MultiSelectFilterOption<any, any>>) => void;
  translatedKey?: string;
}

const MultiSelectFilter = ({
  options,
  label,
  defaultValue = [],
  width = 280,
  disabled,
  loading = false,
  onSelect,
  translatedKey,
}: MultiSelectFilterProps) => {
  const { t } = useTranslation([translatedKey ?? '']);
  const [hasChanged, setHasChanged] = React.useState<boolean>(false);
  const [selected, setSelected] =
    React.useState<Array<MultiSelectFilterOption<any, any>>>([]);

  useEffect(() => {
    if (defaultValue && options) {
      const mapdefaultValue = defaultValue.map(el => el.value);
      const defaultSelectedOptions: MultiSelectFilterOption<any, any>[] = [];
      options.map(option => {
        if(mapdefaultValue.indexOf(option.value) !== -1) {
          defaultSelectedOptions.push(option)
        }
      })
      setSelected(defaultSelectedOptions);
    }
  }, [options]);

  return (
    <FormControl
      style={{
        width: width ?? 280,
      }}
    >
      <InputLabel
        id="multi-select-label"
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
        variant="standard"
      >
        {label}
        {loading && (
          <CircularProgress
            style={{
              width: '12px',
              height: '12px',
            }}
          />
        )}
      </InputLabel>
      <Select
        labelId="multi-select-input"
        variant="standard"
        multiple
        value={selected}
        onChange={(e: any) => {
          setHasChanged(true);
          setSelected(
            e.target.value as Array<MultiSelectFilterOption<any, any>>
          );
        }}
        renderValue={(value: any) => {
          let res: string = '';
          value.forEach(
            (item: MultiSelectFilterOption<any, any>, index: number) => {
              const resItem: string = translatedKey
                ? t(`${translatedKey}:${item.name}`)
                : item.name;
              if (index == 0) res = resItem;
              else res = res + ', ' + resItem;
            }
          );
          return res;
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 360,
              width: width ?? 280,
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          variant: 'menu',
        }}
        disabled={disabled}
        onClose={() => {
          if (hasChanged) {
            setHasChanged(false);
            if (onSelect !== undefined) {
              onSelect(selected);
            }
          }
        }}
      >
        {options &&
          options.map((option: MultiSelectFilterOption<any, any>) => {
            return (
              <MenuItem
                disabled={disabled}
                key={option.value}
                value={option as any}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={
                      !!selected.find(
                        (item: MultiSelectFilterOption<any, any>) =>
                          item.value === option.value
                      )
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    translatedKey
                      ? t(`${translatedKey}:${option.name}`)
                      : option.name
                  }
                />
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

export default MultiSelectFilter;
