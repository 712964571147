import { Icon } from '@mui/material';

interface LocationIconProps {
    height: number;
    width: number;
    color: string;
}

const LocationIcon = ({ height, width, color }: LocationIconProps) => (
  <Icon style={{ height: height ? height : 25, width: width ? width : 25 }}>
    <svg
      width={width ? width : 25}
      height={height ? height : 25}
      viewBox="0 0 24 24"
      fill={color ? color : 'black'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 11V3H7V7H3V21H11V17H13V21H21V11H17ZM7 19H5V17H7V19ZM7 15H5V13H7V15ZM7 11H5V9H7V11ZM11 15H9V13H11V15ZM11 11H9V9H11V11ZM11 7H9V5H11V7ZM15 15H13V13H15V15ZM15 11H13V9H15V11ZM15 7H13V5H15V7ZM19 19H17V17H19V19ZM19 15H17V13H19V15Z"
        fillOpacity="0.6"
      />
    </svg>
  </Icon>
);

export default LocationIcon;
