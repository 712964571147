import { RiskIconItem } from '../work-procedure/RiskAssessmentInterface';
import { PaginationResponse } from '../common/PaginationInterface';

export interface REPETITION {
  repetition: number;
  repeat_on: Array<string | number>;
  repeat_at_ordinal: string;
  repeat_at_every: number;
  repeat_at: string;
}

export interface ExposureCategoryInterface {
  id: number;
  name: string;
  description: string;
  child_categories: Array<ExposureCategoryInterface>;
  category: string;
  type_of_exposure: string | undefined;
}

export interface ExposureCategoriesByCategoryInterface {
  category: Array<ExposureCategoryInterface>;
  asbestos: Array<ExposureCategoryInterface>;
  radiation: Array<ExposureCategoryInterface>;
}

export interface ExposureTypeInterface {
  type_of_exposure: string;
  assessed_concentration_value: string | null;
  measured_concentration_value: number | null;
  measured_concentration_measurement: string | null;
}

export interface ExposureInterface {
  id: number;
  name: string;
  description: string;
  category: ExposureCategoryInterface;
  exposure: string;
  workplace: string | null;
  job: string | null;
  date_of_addition: string;
  exposure_date: string;
  duration: number;
  date_of_examination: string | null;
  name_of_doctor: string | null;
  ppe: Array<RiskIconItem>;
  exposure_types: Array<ExposureTypeInterface>;
  status: string;
  location: string;
  created_by: string;
}

export interface ExposuresTableColumnProps {
  id: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined;
  minWidth: number;
  title: string;
  maxWidth?: number;
}

export interface ExposuresTableProps {
  page: number;
  rowsPerPage: number;
  setPage: (page: number) => void;
  setRowsPerPage: (rowsPerPage: number) => void;
  count: number;
  rows: Array<ExposureInterface>;
  fetchExposures: () => void;
}

export interface ExposuresResponse
  extends PaginationResponse<ExposureInterface> {}

export interface UserAssigmentInterface {
  work_procedure_name: string;
  exposure_date: string;
  status: string;
}

export interface MyExposuresResponse
  extends PaginationResponse<UserAssigmentInterface> {}

export interface FiltersChoices {
  locations: Array<{ value: number; name: string }>;
}
export interface MyExposuresFiltersChoices {
  work_procedures: Array<{ value: number; name: string }>;
}

export enum EXPOSURE_STATUS {
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  ARCHIVED = 'archived',
  DELETED = 'deleted',
}

export enum MY_EXPOSURE_STATUS {
  NOT_COMPLETED = 'not_completed',
  COMPLETED = 'completed',
  IN_PROGRESS = 'in_progress',
  MISSED = 'missed',
}

interface ExposuresStatusOptionInterface {
  name: EXPOSURE_STATUS;
  value: EXPOSURE_STATUS;
}

interface MyExposuresStatusOptionInterface {
  name: MY_EXPOSURE_STATUS;
  value: MY_EXPOSURE_STATUS;
}

export const EXPOSURE_STATUS_OPTIONS: Array<ExposuresStatusOptionInterface> = [
  {
    name: EXPOSURE_STATUS.IN_PROGRESS,
    value: EXPOSURE_STATUS.IN_PROGRESS,
  },
  {
    name: EXPOSURE_STATUS.COMPLETED,
    value: EXPOSURE_STATUS.COMPLETED,
  },
  {
    name: EXPOSURE_STATUS.ARCHIVED,
    value: EXPOSURE_STATUS.ARCHIVED,
  },
  {
    name: EXPOSURE_STATUS.DELETED,
    value: EXPOSURE_STATUS.DELETED,
  },
];

export const MY_EXPOSURE_STATUS_OPTIONS: Array<MyExposuresStatusOptionInterface> =
  [
    {
      name: MY_EXPOSURE_STATUS.IN_PROGRESS,
      value: MY_EXPOSURE_STATUS.IN_PROGRESS,
    },
    {
      name: MY_EXPOSURE_STATUS.COMPLETED,
      value: MY_EXPOSURE_STATUS.COMPLETED,
    },
    {
      name: MY_EXPOSURE_STATUS.MISSED,
      value: MY_EXPOSURE_STATUS.MISSED,
    },
    {
      name: MY_EXPOSURE_STATUS.NOT_COMPLETED,
      value: MY_EXPOSURE_STATUS.NOT_COMPLETED,
    },
  ];

export const DEFAULT_EXPOSURE_STATUS_OPTION: Array<ExposuresStatusOptionInterface> =
  [EXPOSURE_STATUS_OPTIONS[0], EXPOSURE_STATUS_OPTIONS[1]];

export const DEFAULT_MY_EXPOSURE_STATUS_OPTION: Array<MyExposuresStatusOptionInterface> =
  [
    MY_EXPOSURE_STATUS_OPTIONS[1],
    MY_EXPOSURE_STATUS_OPTIONS[2],
    MY_EXPOSURE_STATUS_OPTIONS[3],
  ];
